import React from 'react'
import {graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text,Link} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Aktivitaet = ({data}) => {

return(
    <div>
        <MySEO
        title="Kulturnetz K.O.M."
        description="Gestaltung des Kulturnetz Welterbe Oberes Mittelrheintal"
        />

    <Heading># kulturaktivitäten</Heading>

      <Text variant="bildtext"><b>K.O.M. Kulturnetz Oberes Mittelrheintal e.V. – Vernetzung schafft neue Impulse. </b> Im Herbst 2020 wurde das Kulturnetz Oberes Mittelrheintal als gemeinnütziger Verein von Katrin Gloggengießer, René Broich und sieben weiteren Kulturakteuren gegründet. hasen oder bein entwarf das Corporate Design und wird alle Kulturveranstaltungen, wie die ersten beiden Salons, die ab Juli 2021 geplant sind, visuell betreuen und begleiten.
       Geplant ist außerdem eine jährliche, später vielleicht auch halb- oder vierteljährliche Vereins- und Veranstaltungschronik im Online wie im Printformat.
       Weiteres siehe <a href="https://kulturnetz-oberes-mittelrheintal.org" rel="noopener noreferrer" target="_blank"><Link textDecoration="underline">&rarr; K.O.M.</Link></a> </Text>
    <Flex>

           <Box width={["100%","100%","49%"]}>
                <GatsbyImage alt="K.O.M.-Salon Banner" image= {data.bilder.edges[0].node.childImageSharp.gatsbyImageData} />

           </Box>

       <Box width={["100%","100%","49%"]}>
            <GatsbyImage alt="K.O.M.-Salon Banner" image= {data.bilder.edges[1].node.childImageSharp.gatsbyImageData} />
       </Box>
      </Flex>

    </div>
)}


export const AktiQuery = graphql`
 query {
  bilder:allFile(filter: {absolutePath: {regex: "/_kom/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
 }


`

  export default Aktivitaet
